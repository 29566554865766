
import React, { Component } from 'react';
import './GimsReports.css';
import { Sidebar, Header } from '..';
import { Tab, Tabs, DropdownButton, Dropdown, Button, Modal } from 'react-bootstrap';
import { Growl } from 'primereact/growl';
import GimsReportChartLayout from './GimsReportChartLayout/GimsReportChartLayout';
import CountryTotals from './CountryTotals/CountryTotals';
import EvaluationReport from './EvaluationReport/EvaluationReport';
import EndpointConstant from '../../constants/EndpointConstant';
import utils from '../../utils';
import PopupModels from '../PopupModels/PopupModels';
import MessageConstant from '../../constants/MessageConstant';
import PropTypes from 'prop-types';
import RoleConstant from "./../../constants/RoleConstant";
import { ProgressBar } from "primereact/progressbar";
import { v4 as uuidV4 } from "uuid";
import * as uuid from 'uuid';
import ConfirmationModal from './../PopupModels/ConfirmationModal';
const repeatCallTimeDelayInMS = 10000;

/**
 * A class that generate gims report 
 **/
class GimsReports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // Takes active tab from props if it is defined there
      activeTab: props.activeTab || "1",
      showChecked: false,
      sectionOne: false,
      sectionTwo: false,
      sectionThree: false,
      sectionFour: false,
      sectionFive: false,
      sectionSix: false,
      sectionSeven: false,
      sectionEight: false,
      selectionData: [],
      datasetList: [],
      showEmailDialog: false,
      popUpFlag: '',
      popUpMessage: '',
      downloadRequestSuccess: false,
      sendEmailRequestSuccess: false,
      filterDataforExcelDownload: {},
      downloadRequest: "Processing",
      deleteConfirmation : false,
      sId: "",
      downloadUrl: "",
      showDataSync: false,
      extractValue: 0,
      extractSuccess: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCheckToSelect = this.handleCheckToSelect.bind(this);
    this.printAll = this.printAll.bind(this);
    this.downloadAll = this.downloadAll.bind(this);
    this.emailAll = this.emailAll.bind(this);
    this.getCheckboxFromProps = this.getCheckboxFromProps.bind(this);
    this.generatePrint = this.generatePrint.bind(this);
    this.generateCanvas = this.generateCanvas.bind(this);
    this.generateEmail = this.generateEmail.bind(this);
    this.unCheckAll = this.unCheckAll.bind(this);
    this.downloadCountryTotals = this.downloadCountryTotals.bind(this);
    this.onDownloadFilter = this.onDownloadFilter.bind(this);
    this.filteredDataToDownload = {};
    this.CountryTotalSendEmail = this.CountryTotalSendEmail.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
    this.getDatasetListForBioconnect =
      this.getDatasetListForBioconnect.bind(this);
    this.getDatasetList = this.getDatasetList.bind(this);
    this.getProcessingDatasetListForBioconnect =
      this.getProcessingDatasetListForBioconnect.bind(this);
    this.interval = null;
    this.hideDataSyncOnCancel = this.hideDataSyncOnCancel.bind(this);
    this.handleExtractSuccessClose = this.handleExtractSuccessClose.bind(this);
    this.handleDeleteConfirmation=this.handleDeleteConfirmation.bind(this);

  }

  /*
   * Handles selected checkbox to download, email and print
   */
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    }, () => {
      this.getCheckboxFromProps({
        sectionOne: this.state.sectionOne,
        sectionTwo: this.state.sectionTwo,
        sectionThree: this.state.sectionThree,
        sectionFour: this.state.sectionFour,
        sectionFive: this.state.sectionFive,
        sectionSix: this.state.sectionSix,
        sectionSeven: this.state.sectionSeven,
        sectionEight: this.state.sectionEight
      });
    });
  }

  /*
   * Uncheck all the checked checkbox 
   */
  unCheckAll() {
    this.setState({
      sectionOne: false,
      sectionTwo: false,
      sectionThree: false,
      sectionFour: false,
      sectionFive: false,
      sectionSix: false,
      sectionSeven: false,
      sectionEight: false
    })
  }

  /*
   * The active tab must be set into the state so that
   * the Tabs component knows about the change and re-renders.
   */
  handleSelect(selectedTab) {
    // let activeTab=this.state.activeTab;
    // console.log("SelectedTab", selectedTab);
    // activeTab = selectedTab;
    this.setState({
      activeTab:selectedTab
    });
  }

  /*
  * Enabling and showing checkbox to 
  * select for download, email and print
  */
  handleCheckToSelect(event) {
    event.preventDefault();
    this.setState({
      showChecked: true,
      selectionData: []
    })
  }

  /*
  * Print All the charts
  */
  printAll(e) {
    e.preventDefault();
    //console.log("dddd", e);
    this.getCheckboxFromProps({
      sectionOne: true,
      sectionTwo: true,
      sectionThree: true,
      sectionFour: true,
      sectionFive: true,
      sectionSix: true,
      sectionSeven: true,
      sectionEight: true
    });
    setTimeout(() => {
      this.generatePrint();
    }, 500)

  }

  /*
  * Download All the charts
  */
  downloadAll(event) {
    event.preventDefault();
    this.getCheckboxFromProps({
      sectionOne: true,
      sectionTwo: true,
      sectionThree: true,
      sectionFour: true,
      sectionFive: true,
      sectionSix: true,
      sectionSeven: true,
      sectionEight: true
    });
    setTimeout(() => {
      this.generateCanvas('download');
    }, 500)
  }
// Download as Excel
downloadExcel(event) {
  event.preventDefault();

  clearInterval(this.interval);
  // console.log("this state",this.state);
  this.interval = null;
  this.setState({
    showDataSync: true,
  });
  this.getDatasetListForBioconnect();
  this.interval = setInterval(async () => {
    let val = this.state.extractValue;
    val += Math.floor(Math.random() * 15) + 1;
    if (val >= 99) {
      val = 99;
    }

    this.setState({
      extractValue: val,
    });
    this.state.downloadRequest == "Processing"
      ? this.getProcessingDatasetListForBioconnect()
      : "";
    if (this.state.downloadRequest == "Completed") {
      this.hideDataSyncOnCancel();
      this.setState({
        extractSuccess: true,
        extractValue: 0,
        extractResponseMsg: MessageConstant.DOWNLOADED_GIMS_REPORT,
        extractResponseFlagIcon: "success",
      });

      clearInterval(this.interval);
      this.interval = null;
    }
  }, 10000);
}

getDatasetListForBioconnect() {
  let data = {}
  data.datasetId = this.state.datasetList.map(dataset => dataset.id);
  data.sId = uuidV4();
  // data.report = true;
  data.sortingData = [];
  this.setState({ downloadRequest: "Processing", sId: data.sId });
  let self = this;
  utils.makeRequestInBackground(
    this,
    EndpointConstant.DOWNLOAD_GIMS_REPORT_AS_EXCEL,
    data,
    function (result) {
      return;
    }
  );
}

getProcessingDatasetListForBioconnect() {
  let data = {
    downloadRequest: this.state.downloadRequest,
    sId: this.state.sId,
  };
  let self = this;
  utils.makeRequestInBackground(
    this,
    EndpointConstant.DOWNLOAD_GIMS_REPORT_AS_EXCEL_STATUS,
    data,
    function (result) {
      if (result.downloadRequest == "Completed") {
        self.setState({
          downloadRequest: result.downloadRequest,
          extractSuccess: true,
          showDataSync: false,
          extractValue: 0,
          extractResponseMsg: MessageConstant.DOWNLOADED_GIMS_REPORT,
          extractResponseFlagIcon: "success",
        });
        window.open(result.url, "Download");
        clearInterval(self.interval);
        self.interval = null;
      } else {
        self.setState({ downloadRequest: result.downloadRequest });
      }
    }
  );
}

hideDataSyncOnCancel() {
  this.setState({
    showDataSync: false,
    extractValue: 0,
    downloadUrl: "",
    sId: "",
    deleteConfirmation: false,
  });
  clearInterval(this.interval);
  this.interval = null;
}

handleExtractSuccessClose() {
  this.setState({
    extractSuccess: false,
  });
}
handleDeleteConfirmation() {
  this.setState({ deleteConfirmation: !this.state.deleteConfirmation});
}

  /*
  * Email All the charts
  */
  emailAll(event) {
    event.preventDefault();
    //console.log("Email All");
    event.preventDefault();
    this.getCheckboxFromProps({
      sectionOne: true,
      sectionTwo: true,
      sectionThree: true,
      sectionFour: true,
      sectionFive: true,
      sectionSix: true,
      sectionSeven: true,
      sectionEight: true
    });
    setTimeout(() => {
      this.generateCanvas('email');
    }, 500)
  }

  /*
  * Getting all the checkbox value from
  * other component as a props
  */
  getCheckboxFromProps(data) {
    const identifiers = Object.keys(data)
    const sectionData = identifiers.filter(function (id) {
      return data[id];
    });
    this.setState({
      selectionData: sectionData
    })
  }

  /*
  * Calculating total height of selected chart or
  * all chart for applying to generate pdf
  */
  // generateTotalHeight() {
  //   let height = 0;
  //   this.state.selectionData.map((d) => {
  //     return document.querySelector('#' + d).offsetHeight;
  //   }).map((data) => {
  //     return height += data;
  //   })
  //   return height;
  // }

  /*
  * Generating selected or all charts to 
  * convert html dom to canvas image and
  * adding to jspdf for pdf generation
  */
  generateHtml2Canvas(selector, pdf, img, x, y, width, height) {
    window.html2canvas(document.querySelector(selector)).then(function (canvas) {
      img = canvas.toDataURL('image/jpeg', 1.0);
      pdf.addImage(img, 'JPEG', x, y, width, height);
    });
  }

  /*
  * Getting width and height of selected or
  * all charts to set the positions in pdf
  */
  generateWidthHeight(selector) {
    let size = {};
    let width = document.querySelector(selector).offsetWidth;
    let height = document.querySelector(selector).offsetHeight;
    size.width = width;
    size.height = height;
    return size;
  }

  /*
  * Generate dynamic y axis for pdf report
  */
 generateDynamicYaxis(selected, current){
   let size={};
   switch(current){
     case 'sectionThree':
       if(selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0){
        size.height = this.generateWidthHeight('#sectionOne').height;
       }
       else{
         size.height = 0;
       }
      break;
      case 'sectionFour':
       if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
       (selected.indexOf('sectionThree')<0)){
        let height = this.generateWidthHeight('#sectionOne').height;
        size.height = height;
       }
       else if((selected.indexOf('sectionOne') < 0 && selected.indexOf('sectionTwo') < 0) &&
       (selected.indexOf('sectionThree')>=0)){
        let height = this.generateWidthHeight('#sectionThree').height + 20;
        size.height = height;
       }
       else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
       (selected.indexOf('sectionThree')>=0)){
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height + 20;
        size.height = height;
       }
       else{
         size.height = 0;
       }
      break;
      case 'sectionFive':
      case 'sectionSix':
       if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
       (selected.indexOf('sectionThree')<0) && (selected.indexOf('sectionFour')<0)){
        let height = this.generateWidthHeight('#sectionOne').height;
        size.height = height;
       }
       else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
       (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')<0)){
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height + 20;
        size.height = height;
       }
       else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
       (selected.indexOf('sectionThree')<0) && (selected.indexOf('sectionFour')>=0)){
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionFour').height + 20;
        size.height = height;
       }
       else if((selected.indexOf('sectionOne') < 0 && selected.indexOf('sectionTwo') < 0) &&
       (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')<0)){
        let height = this.generateWidthHeight('#sectionThree').height + 20;
        size.height = height;
       }
       else if((selected.indexOf('sectionOne') < 0 && selected.indexOf('sectionTwo') < 0) &&
       (selected.indexOf('sectionThree')<0) && (selected.indexOf('sectionFour')>=0)){
        let height = this.generateWidthHeight('#sectionFour').height + 20;
        size.height = height;
       }
       else if((selected.indexOf('sectionOne') < 0 && selected.indexOf('sectionTwo') < 0) &&
       (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')>=0)){
        let height = this.generateWidthHeight('#sectionThree').height + this.generateWidthHeight('#sectionFour').height + 40;
        size.height = height;
       }
       else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
       (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')>=0)){
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height + this.generateWidthHeight('#sectionFour').height + 40;
        size.height = height;
       }
       else{
         size.height = 0;
       }
      break;
      case 'sectionSeven':
      case 'sectionEight':
        if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
        (selected.indexOf('sectionThree')<0 && selected.indexOf('sectionFour')<0 && 
         selected.indexOf('sectionFive')<0 && selected.indexOf('sectionSix')<0)){
        let height = this.generateWidthHeight('#sectionOne').height;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
        (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')<0) &&
        (selected.indexOf('sectionFive') < 0 && selected.indexOf('sectionSix') < 0)){
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height + 20;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
        (selected.indexOf('sectionThree')<0) && (selected.indexOf('sectionFour')>=0) &&
        (selected.indexOf('sectionFive') < 0 && selected.indexOf('sectionSix') < 0)) {
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionFour').height + 20;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
        (selected.indexOf('sectionThree')<0) && (selected.indexOf('sectionFour')<0) &&
        (selected.indexOf('sectionFive') >= 0 || selected.indexOf('sectionSix') >= 0)) {
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionFive').height + 20;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
        (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')<0) &&
        (selected.indexOf('sectionFive') >= 0 || selected.indexOf('sectionSix') >= 0)) {
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height + this.generateWidthHeight('#sectionFive').height + 20;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
        (selected.indexOf('sectionThree')<0) && (selected.indexOf('sectionFour')>=0) &&
        (selected.indexOf('sectionFive') >= 0 || selected.indexOf('sectionSix') >= 0)) {
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionFour').height + this.generateWidthHeight('#sectionFive').height + 20;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
        (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')>=0) &&
        (selected.indexOf('sectionFive') < 0 && selected.indexOf('sectionSix') < 0)) {
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height + this.generateWidthHeight('#sectionFour').height + 40;
        size.height = height;
        }
        
        else if((selected.indexOf('sectionOne') < 0 && selected.indexOf('sectionTwo') < 0) &&
        (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')<0) && 
        (selected.indexOf('sectionFive') < 0 && selected.indexOf('sectionSix') < 0)){
        let height = this.generateWidthHeight('#sectionThree').height + 20;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') < 0 && selected.indexOf('sectionTwo') < 0) &&
        (selected.indexOf('sectionThree')<0) && (selected.indexOf('sectionFour')>=0) &&
        (selected.indexOf('sectionFive') < 0 && selected.indexOf('sectionSix') < 0)){
        let height = this.generateWidthHeight('#sectionFour').height + 20;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') < 0 && selected.indexOf('sectionTwo') < 0) &&
        (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')<0) &&
        (selected.indexOf('sectionFive') >= 0 || selected.indexOf('sectionSix') >= 0)){
        let height = this.generateWidthHeight('#sectionThree').height + this.generateWidthHeight('#sectionFive').height + 40;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') < 0 && selected.indexOf('sectionTwo') < 0) &&
        (selected.indexOf('sectionThree')<0) && (selected.indexOf('sectionFour')>=0) &&
        (selected.indexOf('sectionFive') >= 0 || selected.indexOf('sectionSix') >= 0)){
        let height = this.generateWidthHeight('#sectionFour').height + this.generateWidthHeight('#sectionFive').height + 40;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') < 0 && selected.indexOf('sectionTwo') < 0) &&
        (selected.indexOf('sectionThree')<0) && (selected.indexOf('sectionFour')<0) &&
        (selected.indexOf('sectionFive') >= 0 || selected.indexOf('sectionSix') >= 0)){
        let height =  this.generateWidthHeight('#sectionFive').height + 20;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') < 0 && selected.indexOf('sectionTwo') < 0) &&
        (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')>=0) &&
        (selected.indexOf('sectionFive') >= 0 || selected.indexOf('sectionSix') >= 0)){
        let height = this.generateWidthHeight('#sectionThree').height + this.generateWidthHeight('#sectionFour').height + this.generateWidthHeight('#sectionFive').height + 40;
        size.height = height;
        }
        else if((selected.indexOf('sectionOne') >= 0 || selected.indexOf('sectionTwo') >= 0) &&
        (selected.indexOf('sectionThree')>=0) && (selected.indexOf('sectionFour')>=0) &&
        (selected.indexOf('sectionFive') >= 0 || selected.indexOf('sectionSix') >= 0)) {
        let height = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height + this.generateWidthHeight('#sectionFour').height + this.generateWidthHeight('#sectionFive').height + 60;
        size.height = height;
        }
        else{
          size.height = 0;
        }
      break;
     default: 
      //console.log("no current items");
      break;
   }
   return size;
 }

  /*
  * Setting pdf configuration and 
  * convert the selected chart or all the chart 
  * to base64 and download the pdf or email the base64 data
  */
  generateCanvas(downloadOrEmailArgs) {
    //console.log("download", downloadOrEmailArgs);
    //console.log("SelectionData", this.state.selectionData);
    let pdfWidth = document.getElementById("tabGimsreport").offsetWidth + 500;
    let pdfHeight = document.getElementById("tabGimsreport").offsetHeight + 1800;
    var selectedData = this.state.selectionData;
    if (selectedData.length > 0) {
      utils.showLoader();
      var pdf = new window.jsPDF({orientation:'p', unit: 'px', format: [pdfWidth, pdfHeight]});
      // var pageHeight = pdf.internal.pageSize.height;
      // var pageWidth = pdf.internal.pageSize.width;
      //console.log("pageHeight", pageHeight);
      //console.log("pageWidth", pageWidth);

      //console.log("this.generateTotalHeight", this.generateTotalHeight());
      var myImage = '';
      for (let i = 0; i < selectedData.length; i++) {
        if (selectedData[i] === 'sectionOne') {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          this.generateHtml2Canvas(`#${selectedData[i]}`, pdf, myImage, 10, 20, widHgt.width, widHgt.height);
          //console.log("1");
        }
        if (selectedData[i] === 'sectionTwo') {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          let xAxis = selectedData.indexOf('sectionOne')>=0?this.generateWidthHeight('#sectionOne').width + 30 : 10;
          this.generateHtml2Canvas(`#${selectedData[i]}`, pdf, myImage, xAxis, 20, widHgt.width, widHgt.height);
          //console.log("1");
        }
        if (selectedData[i] === 'sectionThree') {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          this.generateHtml2Canvas(`#${selectedData[i]}`, pdf, myImage, 10, this.generateDynamicYaxis(selectedData,'sectionThree').height + 40, widHgt.width, widHgt.height);
          //console.log("1");
        }
        if (selectedData[i] === 'sectionFour') {
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          this.generateHtml2Canvas(`#${selectedData[i]}`, pdf, myImage, 10, this.generateDynamicYaxis(selectedData,'sectionFour').height + 40, widHgt.width, widHgt.height);
          //console.log("1");
        }
        if (selectedData[i] === 'sectionFive') {
          //let oneThree = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height;
          //let secFiveHgt = this.generateWidthHeight('#sectionFour');
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          this.generateHtml2Canvas(`#${selectedData[i]}`, pdf, myImage, 10, this.generateDynamicYaxis(selectedData,'sectionFive').height + 40, widHgt.width, widHgt.height);
          //console.log("1");
        }
        if (selectedData[i] === 'sectionSix') {
          //let oneThree = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height;
          //let secSixHgt = this.generateWidthHeight('#sectionFour');
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          let xAxis = selectedData.indexOf('sectionFive')>=0?this.generateWidthHeight('#sectionFive').width + 30 : 10;
          this.generateHtml2Canvas(`#${selectedData[i]}`, pdf, myImage, xAxis, this.generateDynamicYaxis(selectedData,'sectionSix').height + 40, widHgt.width, widHgt.height);
          //console.log("1");
        }
        if (selectedData[i] === 'sectionSeven') {
          //let oneThree = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height;
          //let secFiveHgt = this.generateWidthHeight('#sectionFour').height + this.generateWidthHeight('#sectionFive').height;
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          this.generateHtml2Canvas(`#${selectedData[i]}`, pdf, myImage, 10, this.generateDynamicYaxis(selectedData,'sectionSeven').height + 40, widHgt.width, widHgt.height);
          //console.log("1");
        }
        if (selectedData[i] === 'sectionEight') {
          //let oneThree = this.generateWidthHeight('#sectionOne').height + this.generateWidthHeight('#sectionThree').height;
          //let secSixHgt = this.generateWidthHeight('#sectionFour').height + this.generateWidthHeight('#sectionFive').height;
          let widHgt = this.generateWidthHeight(`#${selectedData[i]}`);
          let xAxis = selectedData.indexOf('sectionSeven')>=0?this.generateWidthHeight('#sectionSeven').width + 30 : 10;
          this.generateHtml2Canvas(`#${selectedData[i]}`, pdf, myImage, xAxis, this.generateDynamicYaxis(selectedData,'sectionEight').height + 40, widHgt.width, widHgt.height);
          //console.log("1");
        }
        setTimeout(() => {
          if (i === selectedData.length - 1) {
            utils.hideLoader();
            if (downloadOrEmailArgs === 'email') {
              const binaryEmail = btoa(pdf.output());
              this.generateEmail(binaryEmail);
            }
            else {
              pdf.save("download.pdf");
            }
            //console.log("21");
          }
        }, 20000)

      }
    }
    else {
      //alert("Please select to " + downloadOrEmailArgs);
      this.growl.show({severity:'error', summary: 'Error', detail:`Please select the report to ${downloadOrEmailArgs}`, life: 5000});
    }

    this.setState({
      showChecked: false,
      selectionData: []
    });

    this.unCheckAll();
  }

  /*
  * Print selected or all the charts
  */
  generatePrint() {
    var selectedData = this.state.selectionData;
    if (selectedData.length > 0) {
      var printContent = '';
      for (let i = 0; i < selectedData.length; i++) {
        printContent += document.getElementById(`${selectedData[i]}`).innerHTML;
      }
      var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(printContent);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    }
    else {
      //alert("Please select to print");
      this.growl.show({severity:'error', summary: 'Error', detail:`Please select the report to print`, life: 5000});
    }

    this.setState({
      showChecked: false,
      selectionData: []
    });
    this.unCheckAll();
  }

  /*
  * Getting base64 data for selected or all the charts to 
  * send the data to server for sending reports via email
  */
  generateEmail(basePdf) {
    //console.log("generateEmail", basePdf);
    const generatedId = uuid.v4();
    let data = {getGeneratedResultId: generatedId,};
    let self = this;
    let header = { 'Content-Type': 'application/json' };
    data.type = "GIMSReport";
    data.data = basePdf;
    data.contentType = 'application/pdf';
    // utils.makeRequest(this, EndpointConstant.GIMS_REPORT_SEND_EMAIL, data, function (result) {
    //   //console.log("email result", result);
    //   if (result.error === false) {
    //     self.setState({ showEmailDialog: true, popUpFlag: 'success', popUpMessage: MessageConstant.EMAIL_SUCCESS_MESSAGE });
    //   }
    //   else {
    //     self.setState({ showEmailDialog: true, popUpFlag: 'error', popUpMessage: MessageConstant.EMAIL_FAILED_MESSAGE });
    //   }
    // }, header);

    this.callGenerateEmail(data);

    this.setState({
      showChecked: false,
      selectionData: []
    });
    this.unCheckAll();
  }

  callGenerateEmail(filteredData, continous) {
    const generatedId = uuid.v4();
    let data = filteredData ? filteredData : {getGeneratedResultId: generatedId,};
    let header = { 'Content-Type': 'application/json' };
    let self = this;
    utils.showLoader();  
    if (!continous) {
      setTimeout(() => {data.getGeneratedResult = true;self.callGenerateEmail(data, true)}, repeatCallTimeDelayInMS);
    }
    utils.makeRequest(this, EndpointConstant.GIMS_REPORT_SEND_EMAIL, data, function (result) {
      //console.log("Download country totals result", result);
      if (result.error === 'false' || result.error === false) {
        utils.hideLoader()
        self.setState({ showEmailDialog: true, popUpFlag: 'success', popUpMessage: MessageConstant.EMAIL_SUCCESS_MESSAGE });
      } else {
          utils.showLoader()
          data.getGeneratedResult = true;
          setTimeout(() => {self.callGenerateEmail(data, true)}, repeatCallTimeDelayInMS);
      }
    }, header);
  }

  /*
  * Handling close the confirmation popup
  */
  handleDeleteClose() {
    this.setState({ showEmailDialog: false })
  }

  /*
  * Country totals searched result 
  * data from country total component as props
  */
  onDownloadFilter(filteredData) {
    if (filteredData.hasOwnProperty("getGeneratedResult")) {
      delete filteredData.getGeneratedResult;
    }
    this.filteredDataToDownload = filteredData
  }

  /*
  * Download country totals records from webservice api
  */
  downloadCountryTotals(event) {    
    event.preventDefault();
    //console.log("downloadcountry total", this.filteredDataToDownload);
    let self = this;
    let data = Object.assign({}, this.filteredDataToDownload);
    const generatedId = uuid.v4();
    data['getGeneratedResultId'] = generatedId;
    this.setState({ downloadRequestSuccess: false }, () => self.callDownloadCountryTotals(data));
  }

  callDownloadCountryTotals(filteredData, continous) {
    if (this.state.downloadRequestSuccess)
      return true;
    const generatedId = uuid.v4();
    let data = filteredData ? filteredData : {getGeneratedResultId: generatedId,};
    let self = this;
    utils.showLoader();
    if (!continous) {
      setTimeout(() => {data.getGeneratedResult = true;self.callDownloadCountryTotals(data, true)}, repeatCallTimeDelayInMS);
    }
    utils.makeRequest(this, EndpointConstant.DOWNLOAD_COUNTRY_TOTALS, data, function (result) {
      //console.log("Download country totals result", result);
      if (result.error === 'false' || result.error === false) {
        utils.hideLoader()
        self.setState({ downloadRequestSuccess: true });
        window.open(result.downloadUrl, 'Download');
      } else {
          utils.showLoader()
          data.getGeneratedResult = true;
          setTimeout(() => {self.callDownloadCountryTotals(data, true)}, repeatCallTimeDelayInMS);
      }
    });
  }

  /*
  * Email country totals records from webservice api
  */
  CountryTotalSendEmail(event) {
    event.preventDefault();
    const generatedId = uuid.v4();
    let data = {getGeneratedResultId: generatedId,}
    let self = this;
    data.type = "CountryTotals";
    data.contentType = 'application/json';
    data.countryTotals = this.filteredDataToDownload;
    this.setState({ sendEmailRequestSuccess: false }, () => self.callCountryTotalSendEmail(data));
    // utils.makeRequest(this, EndpointConstant.GIMS_REPORT_SEND_EMAIL, data, function (result) {
    //   //console.log("email result", result);
    //   if (result.error === false) {
    //     self.setState({ showEmailDialog: true, popUpFlag: 'success', popUpMessage: MessageConstant.EMAIL_SUCCESS_MESSAGE });
    //   }
    //   else {
    //     self.setState({ showEmailDialog: true, popUpFlag: 'error', popUpMessage: MessageConstant.EMAIL_FAILED_MESSAGE });
    //   }
    // });
  }

  callCountryTotalSendEmail(filteredData, continous) {
    if (this.state.sendEmailRequestSuccess)
          return true;
    const generatedId = uuid.v4();
    let data = filteredData ? filteredData : {getGeneratedResultId: generatedId,};
    let self = this;
    utils.showLoader();
    if (!continous) {
        setTimeout(() => {data.getGeneratedResult = true;self.callCountryTotalSendEmail(data, true)}, repeatCallTimeDelayInMS);
    }
    utils.makeRequest(this, EndpointConstant.GIMS_REPORT_SEND_EMAIL, data, function (result) {
      //console.log("Download country totals result", result);
      if (result.error === 'false' || result.error === false) {
        utils.hideLoader();
        self.setState({ sendEmailRequestSuccess: true });
        self.setState({ showEmailDialog: true, popUpFlag: 'success', popUpMessage: MessageConstant.EMAIL_SUCCESS_MESSAGE });
      } else {
          utils.showLoader()
          data.getGeneratedResult = true;
          setTimeout(() => {self.callCountryTotalSendEmail(data, true)}, repeatCallTimeDelayInMS);
      }
    });
  }

  getDatasetList(datasetList) {
    console.log('dataset received',datasetList.length)
    this.setState({datasetList: datasetList})
  }

  /*
  * Rendering component layout
  */
  render() {
    const {activeTab,showChecked}=this.state;
    //console.log("active tab",this.state.activeTab);
    return (
      <div>
        <Header />
        <div className="sidebar">
          <Sidebar />
        </div>
        <Growl ref={el => (this.growl = el)} />
        <div className="menu-content tab-content" id="tabGimsreport">
          <Tabs  activeKey={activeTab} onSelect={this.handleSelect}>
            <Tab eventKey={1} title="GIMS REPORTS">
            {activeTab === "1" ? <GimsReportChartLayout showChecked={showChecked}
                state={this.state}
                getDatasetList={this.getDatasetList}
                handleChange={this.handleChange}/> : null}
            </Tab>
            <Tab eventKey={2} title="COUNTRY TOTALS" >
            {activeTab === "2" ? <CountryTotals onDownloadFilter={this.onDownloadFilter} /> : null }
            </Tab>
            {utils.getSessionItem("role") === RoleConstant.Admin ?
              <Tab eventKey={3} title="EVALUATION REPORT" >
                 {activeTab === "3" ?<EvaluationReport onDownloadFilter={this.onDownloadFilter} />: null }
              </Tab>
              : ''}
            </Tabs>
          {activeTab === "1" ? (
            <div className="chart-operations">
              {
                !showChecked ?(
                  <React.Fragment>
                    <DropdownButton id="dropdown-basic-button" title={
                      <span><img src='/Images/ico-print.png' alt="Print" />Print</span>
                    }>
                      <Dropdown.Item onClick={this.printAll}>Print All</Dropdown.Item>
                      <Dropdown.Item onClick={this.handleCheckToSelect}>Print Selected</Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton id="dropdown-basic-button1" title={
                      <span><img src='/Images/ico-download-all.png' alt="Download" />Download</span>
                    }>
                      <Dropdown.Item onClick={this.downloadAll}>Download All</Dropdown.Item>
                      <Dropdown.Item onClick={this.handleCheckToSelect}>Download Selected</Dropdown.Item>
                      <Dropdown.Item onClick={this.downloadExcel}>Download as Excel</Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton id="dropdown-basic-button2" title={
                      <span><img src='/Images/ico-email-all.png' alt="Email" />Email</span>
                    }>
                      <Dropdown.Item onClick={this.emailAll}>Email All</Dropdown.Item>
                      <Dropdown.Item onClick={this.handleCheckToSelect}>Email Selected</Dropdown.Item>
                    </DropdownButton>
                  </React.Fragment> )
                 : 
                   ( <div className="btn-operation">
                      <Button variant="secondary" className='clear' onClick={this.generatePrint} ><span><img src='/Images/ico-print.png' alt="Print" />Print</span></Button>
                      <Button variant="secondary" className='clear' onClick={() => this.generateCanvas('download')} ><span><img src='/Images/ico-download-all.png' alt="Download" />Download</span></Button>
                      <Button variant="secondary" className='clear' onClick={() => this.generateCanvas('email')}><span><img src='/Images/ico-email-all.png' alt="Email" />Email</span></Button>
                    </div>)  
              }
            </div>)          
           :
            (<div className="chart-operations">
              { activeTab === "2" ? (               
              <div className="btn-operation">
                {/*  <Button variant="secondary" className='clear' onClick={this.generatePrint} ><span><img src='/Images/ico-print.png' alt="Print" />Print</span></Button> */}
                <Button variant="secondary" className='clear' onClick={this.downloadCountryTotals} ><span><img src='/Images/ico-download-all.png' alt="Download" />Download</span></Button>
                <Button variant="secondary" className='clear' onClick={this.CountryTotalSendEmail}><span><img src='/Images/ico-email-all.png' alt="Email" />Email</span></Button>
              </div>)
              : (<div className="btn-operation"></div>)
              }
            </div>)
         }
         <Modal
            show={this.state.showDataSync}
            onHide={this.hideDataSyncOnCancel}
            className="dataset-extract-dialog"
          >
            <Modal.Header closeButton>
              <Modal.Title>DOWNLOAD GIMS REPORT </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProgressBar
                style={{
                  height: "10px",
                  borderRadius: "5px",
                  border: "1px solid #0063C3",
                  background: "#F2F2F2",
                }}
                showValue={false}
                value={this.state.extractValue}
              />
              <p className="progressMsg">Downloading is in progress...</p>
              <p className="progressMsg">
                {this.state.extractValue}% completed
              </p>
            </Modal.Body>
            <Modal.Footer className="confirm-popup-header">
              <Button
                variant="primary"
                className="clear "
                onClick={this.handleDeleteConfirmation}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <PopupModels
            showPopup={this.state.extractSuccess}
            iconFlag={this.state.extractResponseFlagIcon}
            message={this.state.extractResponseMsg}
            handleClosePopup={this.handleExtractSuccessClose.bind(this)}
          />
          <ConfirmationModal showModal={this.state.deleteConfirmation}
        handleCloseModal={this.handleDeleteConfirmation.bind(this)}
        message={MessageConstant.CANCEL_DOWNLOAD}
        navigate={this.hideDataSyncOnCancel} />
        
          <PopupModels showPopup={this.state.showEmailDialog} iconFlag={this.state.popUpFlag} message={this.state.popUpMessage} handleClosePopup={this.handleDeleteClose.bind(this)} />
        </div>
      </div>
    )
  }
}
GimsReports.propTypes = {
  activeTab: PropTypes.string
}

export default GimsReports;